<template>

  <v-main>

    <v-container
        id="resources-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-link-variant"
          class="px-5 py-3"
      >

        <v-simple-table>
          <tbody>

            <tr>
              <td>
                <a :href="`${baseUrl}docs/tilyet_bylaws_2022.pdf`" target="_blank">Tilyet Benevolence Fund Bylaws</a>
              </td>
            </tr>

          </tbody>

        </v-simple-table>

      </base-material-card>

    </v-container>
  </v-main>

</template>

<script>


export default {
  name: "CoreResourcesView",
  created() {
  },
  components: {
  },
  methods: {},
  data() {
    return {
      baseUrl: process.env.BASE_URL,
    }
  }
};

</script>

<style lang="scss">

th.resource-link {
  width: 10%;
}

</style>
